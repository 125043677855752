import React from "react"
import { DigitalDescriptionStyled } from "./style"
import { Title, SubTitle } from "src/componentsV2/sections/Text"
import howitworksImage from "src/images/home/howitworks.jpg"

const index = ({ className }: any) => {
  return (
    <DigitalDescriptionStyled className={`${className || ""}`}>
      <div className="row items-center justify-between">
        <div className="col md:max-w-98">
          <Title>How digital handbooks by AirMason work</Title>

          <SubTitle className="hidden md:block">
            <p className="mb-4">
              AirMason lets you create stunning digital handbooks and other
              important documents with our easy-to-use editor.
            </p>
            These are then available to your employees online via laptop,
            computer, or any mobile device, complete with search functionalities
            built-in.
          </SubTitle>
          <SubTitle className="md:hidden">
            AirMason lets you create stunning digital handbooks and other
            important documents with our easy-to-use editor.These are then
            available to your employees online via laptop, computer, or any
            mobile device, complete with search functionalities built-in.
          </SubTitle>
        </div>
        <div className="col text-right">
          <img
            src={howitworksImage}
            alt="Howitworks"
            width="726"
            height="422"
          />
        </div>
      </div>
    </DigitalDescriptionStyled>
  )
}
export default index
